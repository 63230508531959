import React, {useState} from 'react';
import './App.scss';


function App() {

    const strength = [0.125, 0.25, 0.5, 0.75, 1, 2, 3 ];
    const conversion = {
        0.125:'1/8',
        0.25:'1/4',
        0.5:'1/2',
        0.75:'3/4',
        1:'1',
        2:'2',
        3:'3'
    }
    // metric
    const [metres, setMetres] = useState(0);
    const [centiMetres, setCentiMetres] = useState(0);
    const [distC, setDistC] = useState(0);
    const [distM, setDistM] = useState(0);

    // imperial
    const [feet, setFeet] = useState(0);
    const [inches, setInches] = useState(0);
    const [distF, setDistF] = useState(0)
    const [distI, setDistI] = useState(0);

    const [distance, setDistance] = useState('feet');
    
    
    const clear = (eve) => {
        if(parseInt(eve.target.value) === 0){
            eve.target.value = null;
        }
    }
    const MetresChanged = (eve) => {
        const value = eve.target.value
        if(value > 0){
            setMetres(Math.round(value));
            const totalCm = (value*100) + parseInt(centiMetres);
            const newInch = (totalCm * 0.39370);
            const newFeet = Math.floor(newInch/12)
            setFeet(newFeet);
            setInches(Math.round(newInch - (newFeet * 12)));
        } else {
            setMetres(0);
        }
    }
    const CMetresChanged = (eve) => {
        const value = eve.target.value
        // limit CM to 100
        if(!(value >= 100) && !(value < 0)){
            setCentiMetres(Math.round(value));
            const totalCm = (metres*100) + parseInt(value);
            const newInch = (totalCm * 0.39370);
            const newFeet = Math.floor(newInch/12)
            setFeet(newFeet);
            setInches(Math.round(newInch - (newFeet * 12)));
        } else if (value >= 100){
            setMetres(metres + 1);
            setCentiMetres(0);
        }
    }

    const FeetChanged = (eve) => {
        const value = eve.target.value
        if(value > 0){
            setFeet(value);
            const feetToCm = value*30.48;
            const inchToCm = inches*2.54;
            const totalCm = feetToCm + inchToCm;
            const cm  = Math.floor(totalCm % 100)
            setCentiMetres(cm);
            setMetres(Math.floor(totalCm - cm) / 100);
        } else {
            setFeet(0);
        }
    }

    const InchChanged = (eve) => {
        let value = eve.target.value
      
        // todo limit inches to 12
        if(!(value >= 12) && !(value < 0)){
            setInches(value);
            const feetToCm = feet*30.48;
            const inchToCm = value*2.54;
            const totalCm = feetToCm + inchToCm;
            const cm  = Math.floor(totalCm % 100)
            setCentiMetres(cm);
            setMetres(Math.floor(totalCm - cm) / 100);
        } else if (value >= 12){
            setFeet(parseInt(feet) + 1);
            setInches(0);
        }
    }

    const distFChanged = (eve) => {
        const value = eve.target.value
        if(value > 0){
            setDistF(value);
            const feetToCm = value*30.48;
            const inchToCm = distI*2.54;
            const totalCm = feetToCm + inchToCm;
            const cm  = Math.floor(totalCm % 100)
            setDistC(cm);
            setDistM(Math.floor(totalCm - cm) / 100);
        } else {
            setDistF(0);
        }
    }

    const distIChanged = (eve) => {
        const value = eve.target.value
        if(!(value >= 12) && !(value < 0)){
            setDistI(value);
            const feetToCm = distF*30.48;
            const inchToCm = value*2.54;
            const totalCm = feetToCm + inchToCm;
            const cm  = Math.floor(totalCm % 100)
            setDistC(cm);
            setDistM(Math.floor(totalCm - cm) / 100);
            
        } else if (value >= 12){
            setDistF(parseInt(distF) + 1);
            setDistI(0);
        }
    }
    const distMChanged = (eve) => {
        const value = eve.target.value
        if(value > 0){
            setDistM(Math.round(value));
            const totalCm = (value*100) + parseInt(distC);
            const newInch = (totalCm * 0.39370);
            const newFeet = Math.floor(newInch/12)
            setDistF(newFeet);
            setDistI(Math.round(newInch - (newFeet * 12)));
        } else {
            setDistM(0);
        }

    }
    const distCChanged = (eve) => {
        const value = eve.target.value
        // limit CM to 100
        if(!(value >= 100) && !(value < 0)){
            setDistC(Math.round(value));
            const totalCm = (distM*100) + parseInt(value);
            const newInch = (totalCm * 0.39370);
            const newFeet = Math.floor(newInch/12)
            setDistF(newFeet);
            setDistI(Math.round(newInch - (newFeet * 12)));
        } else if (value >= 100){
            setDistM(distM + 1);
            setDistC(0);
        }
    }
  
    const toggleMeasure = (eve) => {
        if(distance==='metres'){
            setDistance('feet');
        } else {
            setDistance('metres');
        }
    }

    const totalLength = () => {
        if(!isNaN(metres)){
            if(centiMetres && !isNaN(centiMetres)){
                if(distance === 'feet'){
                    return ((parseInt(feet) - parseInt(distF)) + ( (inches)/12) - (parseInt(distI)/12));
                } else {
                    return ((parseInt(metres) - parseInt(distM)) + ((centiMetres)/100) - (parseInt(distC)/100));
                }
            }
            if(distance === 'feet'){
                return feet;
            } else {
                return metres;
            }
        }
    }

    const convert = (dist) => {
        // meters to m|cm
        let metre = Math.floor(dist).toFixed(0);
        let cm = 0;
        if(dist % 1 !== 0){
            cm = ((dist % 1)*100).toFixed(0);
        }
        // cm to inches
        return [metre, cm];
    }

    const convertToFeet = (dist) => {
       let feet = Math.floor(dist).toFixed(0);
       let inch = 0;
       
       if(dist % 1 !== 0){
            inch = ((dist % 1)*12).toFixed(0);
       }
        return [feet, inch];
    }

    


    return (
        <div className="calc-container">
            <div className="modal" id="login-modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <div className="modal-title ma0">Diopter Calculator</div>
                            <div className="modal-sub-title">
                                <p>n.b. Diopters create a new plane of focus. To line up with your cine lens which measures from the sensor, input the distance between the sensor and the diopter glass.
                                </p>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="distance"  onClick={toggleMeasure}>
                                <span className={"measure " + (distance === 'feet' ? 'active':'')}>Feet </span><span className={"measure " + (distance === 'metres' ? 'active':'')}> Metres</span>
                                <span className={'selected ' + (distance==='feet' ? '':'metres')}></span>
                            </div>
                            <div className="input-boxes">
                                <div>
                                    <h3>Lens' Current Close Focus</h3>
                                    <div className={"inputs " + (distance === 'feet' ? '':'active')}>
                                        <label>
                                            M
                                            <input placeholder="M" required="required"  value={metres} onClick={clear} onChange={MetresChanged} className="form-control" type="number" aria-required="true" />
                                        </label> 
                                        <label>
                                            Cm  
                                            <input placeholder="Cm" required="required" value={centiMetres} onClick={clear} onChange={CMetresChanged} className="form-control" type="number" aria-required="true" />
                                        </label>
                                    </div>
                                    <div className={"inputs " + (distance === 'feet' ? 'active':'')}>
                                        <label>
                                            Ft
                                            <input placeholder="Ft" required="required"   value={feet} onClick={clear} onChange={FeetChanged} className="form-control" type="number" aria-required="true" />
                                        </label>
                                        <label>
                                            In
                                        <input placeholder="In" required="required"  value={inches} onClick={clear} onChange={InchChanged} className="form-control" type="number" aria-required="true" />
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <h3>Distance from Sensor to Diopter</h3>
                                    <div className={"inputs " + (distance === 'feet' ? '':'active')}>
                                        <label>
                                            M
                                            <input placeholder="M" required="required"  value={distM} onClick={clear} onChange={distMChanged} className="form-control" type="number" aria-required="true" />
                                        </label> 
                                        <label>
                                            Cm  
                                            <input placeholder="Cm" required="required" value={distC} onClick={clear} onChange={distCChanged} className="form-control" type="number" aria-required="true" />
                                        </label>
                                    </div>
                                    <div className={"inputs " + (distance === 'feet' ? 'active':'')}>
                                        <label>
                                            Ft
                                            <input placeholder="Ft" required="required"   value={distF} onClick={clear} onChange={distFChanged} className="form-control" type="number" aria-required="true" />
                                        </label>
                                        <label>
                                            In
                                        <input placeholder="In" required="required"  value={distI} onClick={clear} onChange={distIChanged} className="form-control" type="number" aria-required="true" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Strength</th>
                                        <th>Close Focus</th>
                                        <th>Far Focus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Native</td>
                                        <td>
                                            Native
                                        </td>
                                        <td>
                                            Infinity
                                        </td>
                                    </tr>
                                    {
                                        strength.map((diopterStrength) => {
                                            let length = totalLength();
                                            let fMin = 0.0;
                                            if(distance === 'feet'){
                                                // fMin = (length/((diopterStrength * (length))+1));
                                                fMin = (length/(((diopterStrength/3.28) * (length))+1));
                                            } else {
                                                fMin =  (length/((diopterStrength * (length))+1));
                                            }
                                            let fMax = distance === 'feet' ? 3.2808/diopterStrength: 1/diopterStrength;
                                            let [xMaxLarge, xMaxSmall, xMinLarge, xMinSmall] = [0,0,0,0];
                                            if(distance === 'feet'){
                                                [xMaxLarge, xMaxSmall] = convertToFeet(fMax);
                                                [xMinLarge, xMinSmall] = convertToFeet(fMin);
                                                // add distance
                                                //small
                                                xMinLarge = parseInt(xMinLarge) + parseInt(distF);
                                                xMinSmall = parseInt(xMinSmall) + parseInt(distI);
                                                if(xMinSmall >= 12)
                                                {
                                                   xMinSmall = xMinSmall - 12;
                                                   xMinLarge += 1;
                                                }
                                                //large 
                                                xMaxLarge = parseInt(xMaxLarge) + parseInt(distF);
                                                xMaxSmall = parseInt(xMaxSmall) + parseInt(distI);
                                                if(xMaxSmall >= 12)
                                                {
                                                   xMaxSmall = xMaxSmall - 12;
                                                   xMaxLarge += 1;
                                                }
                                            } else {
                                                [xMaxLarge, xMaxSmall] = convert(fMax);
                                                [xMinLarge, xMinSmall] = convert(fMin);
                                                //add distance
                                                xMinLarge = parseInt(xMinLarge) + parseInt(distM);
                                                xMinSmall = parseInt(xMinSmall) + parseInt(distC);
                                                if(xMinSmall >= 100)
                                                {
                                                   xMinSmall = xMinSmall - 100;
                                                   xMinLarge += 1;
                                                }
                                                //large 
                                                xMaxLarge = parseInt(xMaxLarge) + parseInt(distM);
                                                xMaxSmall = parseInt(xMaxSmall) + parseInt(distC);
                                                if(xMaxSmall >= 100)
                                                {
                                                   xMaxSmall = xMaxSmall - 100;
                                                   xMaxLarge += 1;
                                                }
                                            }
                                            
                                            return (
                                                <tr key={diopterStrength}>
                                                    <td dangerouslySetInnerHTML={{__html: conversion[diopterStrength]}}></td>
                                                <td>
                                                    <div className="">
                                                       {xMinLarge}{(distance === 'feet') ? 'ft':'m'} {xMinSmall}{(distance === 'feet') ? 'in':'cm'}
                                                    </div>
                                                </td>
                                                <td>
                                                   {xMaxLarge}{(distance === 'feet') ? 'ft':'m'} {xMaxSmall}{(distance === 'feet') ? 'in':'cm'}
                                                </td>
                                            </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <p className="footer-text">If you’d like to learn how to build your own set of anamorphic lenses like I have, click here to find out more: <a href="https://www.cinesaga.com/anamorphic-course">Anamorphic Course</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
