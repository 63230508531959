import React, {useState} from 'react';
import './App.scss';



function Header() {
    const [showHeader, setShowHeader] = useState(false);  
    const toggleHeader = (eve) => {
        setShowHeader(!showHeader);
    }  
    return (
        <nav className="navbar navbar-default pv6 pv0-xs not-logged-in">
        <div className="container">
            <div className="navbar-header main-nav-navbar-header pb5-xs pt5-xs">
                <div className="main-nav-container">
                    <a className="navbar-brand navbar-logo" title="CineSaga" data-target="draft-page.logoImageLink"
                        href="https://www.cinesaga.com/anamorphic-course" data-external-link-checked="true">
                        <img alt="CineSaga" className="img-responsive" data-target="draft-page.logo"
                            src="https://d31ezp3r8jwmks.cloudfront.net/k18obc7dubnbk97qyks7gd6av7z0" />
                    </a>
                    <a className="navbar-brand hidden" data-target="draft-page.logoTextLink"
                        href="https://www.cinesaga.com/anamorphic-course">CineSaga</a>

                    <button onClick={toggleHeader} type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                        data-target=".navbar-mobile" aria-expanded="false">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>

                    <ul id="customNavLinks" className="main-nav-container-links hidden-xs text-right"
                        data-controller="site-header-links">
                        <li className="hidden">
                            <a href="https://www.cinesaga.com/anamorphic-course"
                                data-external-link-checked="true">/anamorphic-course</a>
                        </li>
                    </ul>

                    <a className="btn btn-primary btn-sm navbar-btn navbar-right hidden-xs"
                        href="https://www.cinesaga.com/login">Login</a>

                    <div className={`navbar-mobile clearfix hidden-sm hidden-md hidden-lg ${showHeader ? 'active':''}`}>
                        <ul className="nav navbar-nav mt8">
                            <li>
                                <a href="https://www.cinesaga.com/login">Login</a>
                            </li>
                            <li className="hidden">
                                <a href="https://www.cinesaga.com/anamorphic-course">/anamorphic-course</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    )
}

export default Header;